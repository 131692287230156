
.navbar-brand {
  font-family: $font-family-serif;
  padding: 20px;
}

.nav-primary {
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
}