
article.episode, article.post {
  border-bottom: 1px dashed $gray-light;
  padding-bottom: $grid-gutter-width;
}
article {
  iframe {
    max-width: 100%;
    height: auto;
  }
  .attachment-thumbnail {
    margin-bottom: 15px;
  }
}

.posts-navigation {
  margin-top: $grid-gutter-width;
  .nav-previous {
    float: right;
    padding-right: 30px;
    position: relative;
    @extend .btn;
    @extend .btn-default;
    &:before {
      @extend .glyphicon;
      @extend .glyphicon-menu-right;
      position: absolute;
      top: 50%;
      right: 0px;
      @include translate(-50%, -50%);
    }
  }
  .nav-next {
    float: left;
    padding-left: 30px;
    position: relative;
    @extend .btn;
    @extend .btn-default;
    &:before {
      @extend .glyphicon;
      @extend .glyphicon-menu-left;
      position: absolute;
      top: 50%;
      left: 15px;
      @include translate(-50%, -50%);
    }
  }
  a {
    text-decoration: none !important;
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}