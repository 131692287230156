// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

label.error {
  color: $red;
  margin: 5px 0px;
  display: inline-block;
  font-weight: 300;
}

#contact-form {
  margin: $grid-gutter-width 0px;
  input[type=submit] {
    margin-top: $grid-gutter-width;
  }
  .cover {
    display: none;
    text-align: center;
    background-color: transparentize($white, .3);
    z-index: 10;
    @include transition(all .5s);
    .glyphicon {
      color: $black;
      top: 50%;
      font-size: 50px;
      margin-top: -25px;
      @include animation(rotating 2s linear infinite);
    }
  }
  &.sending {
    .cover {
      display: block;
    }
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes rotating {
  0% {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}