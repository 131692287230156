
.sidebar {
  .widget {
    margin-bottom: $grid-gutter-width;
    h3 {
      border-bottom: 2px solid $brand-primary;
      padding-bottom: 5px;
    }
    ul {
      @extend .list-unstyled;
      border: 1px solid $gray-light;
      li {
        padding: 10px 12px;
        &:nth-child(odd) {
          background-color: $gray-lighter;
        }
      }
    }
    .textwidget {
      padding: 10px 12px;
      border: 1px solid $gray-light;
    }
  }
}