
.entry-audio {
  margin-bottom: $grid-gutter-width;
}

.entry-gallery {
  margin: $grid-gutter-width 0px;
  padding-top: $grid-gutter-width;
  border-top: 2px solid $brand-primary;
}

.entry-meta {
  padding-left: 3px;
  font-size: 14px;
}

