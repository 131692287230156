
p {
  line-height: 27px;
}

.cover {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.cover-black {
  @extend .cover;
  background-color: transparentize($black, .5);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  @include translate(-50%, -50%);
}

.full-width {
  width: 100%;
}

.no-margin {
  margin: 0px !important;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}