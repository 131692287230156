// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
  margin-top: 60px;
  .home & {
    @media (min-width: $screen-sm-min) {
      margin-top: 0px;
    }
  }
}
.wrap {
  padding: $navbar-height $grid-gutter-width/2;
}