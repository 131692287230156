#cookie_policy {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 15px;
  text-align: center;
  color: #000;
  background-color: #FFF;
  border-top: 1px solid #000;
  z-index: 100000;
  a {
    color: $brand-primary !important;
    text-decoration: underline;
    &.btn {
      color: #000 !important;
      text-decoration: none !important;
      background-color: #FFF;
      &:hover {
        background-color: $brand-primary;
        color: #FFF !important;
      }
    }
  }
}