
.cover-black-gallery {
  @extend .cover-black;
  @include opacity(0);
  @include transition(all .5s);
  h2 {
    margin-top: 30px;
    color: $white;
    text-align: center;
    @extend .centered;
    @include autosize(font-size, 18, 22);
    @include transition(margin-top .5s);
  }
  .glyphicon {
    color: $white;
    @extend .centered;
    @include autosize(font-size, 25, 30);
  }
}

.gallery-container {
  @extend .bg-image;
  @extend .embed-responsive;
  @extend .embed-responsive-4by3;
  position: relative;
  margin-bottom: $grid-gutter-width;
  &:hover {
    .cover-black-gallery {
      @include opacity(1);
      h2 {
        margin-top: 0px;
      }
    }
  }
}

.single-gallery-item {
  @extend .bg-image;
  @extend .embed-responsive;
  @extend .embed-responsive-4by3;
  position: relative;
  margin-bottom: $grid-gutter-width;
  &:hover {
    .cover-black-gallery {
      @include opacity(1);
      .glyphicon {
        margin-top: 0px;
      }
    }
  }
}
