@mixin responsivize($property, $xs-value, $sm-value, $md-value, $lg-value) {
  @media(max-width: $screen-xs-max){
    #{$property}: $xs-value;
  }
  @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    #{$property}: $sm-value;
  }
  @media(min-width: $screen-md-min) and (max-width: $screen-md-max){
    #{$property}: $md-value;
  }
  @media(min-width: $screen-lg-min){
    #{$property}: $lg-value;
  }
}

@mixin autosize($property, $xs-value, $lg-value){
  @include responsivize(
          $property,
          $xs-value * 1px,
          $xs-value + ( ( $lg-value - $xs-value ) * 0.33 ) * 1px,
          $xs-value + ( ( $lg-value - $xs-value ) * 0.66 ) * 1px,
          $lg-value * 1px);
}