
#home-reel-reel {
  width: calc( 100% + 30px );
  margin: 0px -15px 40px -15px;
}
#home-reel {
  width: 100%;
  height: 400px;
}

.home-text {
  line-height: 27px;
}

.home-button {
  margin: 40px 0px 0px 0px;
  background-color: $gray-lighter;
  border: 1px solid $gray-light;
  padding-bottom: 22px;
  position: relative;
  .home-button-image {
    text-align: center;
    position: static;
    min-height: 100px;
    .glyphicon {
      font-size: 50px;
      position: absolute;
      -webkit-transform: translate(-50%, -53%) rotate(0deg) scale(1);
      -ms-transform: translate(-50%, -53%) rotate(0deg) scale(1); // IE9 only
      -o-transform: translate(-50%, -53%) rotate(0deg) scale(1);
      transform: translate(-50%, -53%) rotate(0deg) scale(1);
      @include responsivize(top, 65px, 50%, 50%, 50%);
      @include transition(.5s);
    }
  }
  .home-button-text {
    @include autosize(padding-right, 15, 60);
    h3 {
      border-bottom: 2px solid $brand-primary;
      padding-bottom: 5px;
    }
  }
  &:hover {
    .glyphicon {
      -webkit-transform: translate(-50%, -53%) rotate(-10deg) scale(1.2);
      -ms-transform: translate(-50%, -53%) rotate(-10deg) scale(1.2); // IE9 only
      -o-transform: translate(-50%, -53%) rotate(-10deg) scale(1.2);
      transform: translate(-50%, -53%) rotate(-10deg) scale(1.2);
    }
  }
}